import React from 'react';
import { Link } from 'gatsby';
import Reveal from 'react-reveal/Reveal';

const careers = require('../../data/careers.js');

const Careers = () => (
  <div className="content-column" >
    {Object.entries(careers).map((job, i) => (
      <div key={i} className="agency_featured_item row">
        <Link to={`/about/careers/${job[0]}/`} >
          <div className="agency_featured_content wow fadeInLeft px-2 px-lg-5" data-wow-delay="0.6s">
            <Reveal effect="fadeInRight">
              <div className="dot">
                <span className="dot1" />
                <span className="dot2" />
              </div>
              <h3>{job[1].title}</h3>
              <p>{job[1].description}</p>
            </Reveal>
          </div>
        </Link>
      </div>
    ))}
  </div>
);

export default Careers;
